<template>
  <section class="section-newsletter-banner">
    <TwicImg
      class="bg-image"
      alt="newsletter-image"
      :src="newsletter.image.data.attributes.url"
      ratio="none"
    />
    <div class="content">
      <header class="header">
        <div class="top">
          <span class="title">{{ newsletter.title }}</span>
        </div>
      </header>
      <input ref="emailInput" placeholder="email@address.com" type="text" class="input" />
      <button class="register-button" @click="toggleModal">{{ newsletter.cta }}</button>
    </div>
    <div v-if="isModalOpen" class="overlay" @click="toggleModal"></div>
    <div v-if="isModalOpen" class="modal form-container">
      <NewsletterSubscribe :title="true" :email="email" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Newsletter } from '@/lib/strapi-types/components/Newsletter';

defineProps<{
  newsletter: Newsletter;
}>();

const isModalOpen = ref(false);

const emailInput = ref<HTMLInputElement | null>(null);
const email = ref('');

function toggleModal() {
  if (emailInput.value) {
    email.value = emailInput.value.value;
  }
  isModalOpen.value = !isModalOpen.value;
}
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/mouse-behavior.scss';
@use '$/colors.scss';
@use '$/button.scss';

.section-newsletter-banner {
  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 330px;

  color: white;

  background: rgb(0 0 0 / 40%);

  .content {
    position: relative;

    flex-direction: column;
    gap: 36px;

    max-width: 1040px;
    margin: 0 24px;

    .header {
      flex-direction: column;

      .top {
        flex-direction: row;
        gap: 12px;
        align-items: center;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .input {
      padding: 24px;

      font-size: 18px;
      font-weight: 600;
      color: black;

      border: none;
      border-radius: border-radius.$small;
      outline: none;

      &::placeholder {
        color: colors.$grey-500;
      }
    }

    .register-button {
      @include button.plain($size: large);

      position: absolute;
      right: 8px;
      bottom: 8px;
    }
  }

  .bg-image {
    position: absolute;
    z-index: -1;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: bottom;
  }

  .overlay {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0 0 0 / 50%);
  }

  .modal {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 20px;

    background-color: white;
    border-radius: 8px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (width <= 768px) {
  .section-newsletter-banner {
    height: unset;
    padding: 40px 0;

    .content {
      gap: 32px;
      margin: 0 16px;

      .header {
        gap: 16px;
        align-items: center;

        .top {
          flex-direction: column;
          gap: 0;
        }

        .title {
          font-size: 16px;
          text-align: center;
        }
      }

      .input {
        padding: 8px;
        font-size: 14px;
        border-radius: border-radius.$small;
      }

      .register-button {
        @include button.plain($size: small);

        position: absolute;
        right: 2px;
        bottom: 3px;
      }
    }
  }
}
</style>
